














































































































































import { isEqual, omit } from 'lodash'
import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Ref } from 'vue-property-decorator'
import axios, { CancelTokenSource } from 'axios'

import Confirmation from '@/components/modals/Confirmation.vue'
import CourseSearch from '@/components/_uikit/search/CourseSearch.vue'
import EditMasterGroupModal from '@/components/modals/masterGroups/EditMasterGroupModal.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TableFooter from '@/components/_uikit/TableFooter.vue'
import TransferMasterModal from '@/components/modals/masterGroups/TransferMasterModal.vue'
import UserSocialCard from '@/components/cards/UserSocialCard.vue'
import CourseGroupMonthChainMixin from '@/mixins/CourseGroupMonthChainMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import DictionaryModule from '@/store/modules/dictionary'
import ManagerGroupsModule from '@/store/modules/manager/groups'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import {
  IMasterGroupsFilter,
  IMasterGroupsFilterType,
  MasterGroupShortResource,
  MasterShortResource,
  NameValueChildrenResource,
} from '@/store/types'
import { tableFooterOptions } from '@/utils/constants'

interface ILoadedGroup {
  id: number,
  masters: MasterShortResource[],
}

@Component({
  components: {
    Confirmation,
    CourseSearch,
    EditMasterGroupModal,
    Select,
    TableFooter,
    TextInput,
    TransferMasterModal,
    UserSocialCard,
  },
})
export default class MasterGroupsTable extends Mixins(CourseGroupMonthChainMixin, NotifyMixin, PermissionsMixin) {
  @Ref() confirm!: Confirmation

  private selectedGroup: MasterGroupShortResource | null = null
  private selectedMaster: MasterShortResource | null = null
  private showEditMasterGroupModal = false
  private showTransferMasterModal = false
  private showAdditionalFilters = false
  private masterGroupsExpanded = []
  private loadedGroups: ILoadedGroup[] = []
  private footerOptions = tableFooterOptions

  private get filter () {
    return ManagerGroupsModule.groupsFilter
  }

  private set filter (filter: IMasterGroupsFilter) {
    if (!isEqual(filter, this.filter) || this.masterGroups.length === 0) {
      this.masterGroupsExpanded = []
      this.loadedGroups = []
      ManagerGroupsModule.setGroupsFilter(filter)
      this.$emit('filter', this.filter)
      this.fetchMasterGroups(true)
    }
  }

  private get timezoneGroups () {
    return DictionaryModule.timezoneGroups
  }

  private get subjects () {
    return DictionaryModule.subjects
  }

  private get timezones () {
    return DictionaryModule.timezones
  }

  private get headers () {
    return [
      { sortable: false, text: 'Название', value: 'title', width: '33%' },
      { sortable: false, text: 'Наставник', value: 'mentors', width: '33%' },
      { sortable: false, text: 'Часовая группа', value: 'timezoneGroup', width: '16%' },
      { sortable: false, text: 'Мастера', value: 'mastersCount', width: '8%' },
      { sortable: false, text: '', value: 'actions', width: '7%' },
      { sortable: false, text: '', value: 'data-table-expand', width: '3%' },
    ]
  }

  private get masterGroups () {
    return ManagerGroupsModule.groups.data
  }

  private get pagination () {
    return ManagerGroupsModule.groups.meta
  }

  private isLoading = false
  private source: CancelTokenSource | null = null

  private _handleChangeCourse(course: NameValueChildrenResource, callback: any) {
    this.handleChangeCourse(course, callback)
    this.handleFilter('courseId', course ? course.value : null)
  }

  private _handleChangeGroupType(value: number, callback: any) {
    this.handleChangeGroupType(value, callback)
    this.handleFilter('groupType', value)
  }

  private mounted () {
    DictionaryModule.fetchTimezones()

    this.$bus.$on('add-master-group', this.fetchMasterGroups)
  }

  private getGroupMasters (groupID: number) {
    const group = this.loadedGroups.find((group: ILoadedGroup) => groupID === group.id)
    return group ? group.masters : []
  }

  private classRow() {
    return 'master-groups-table__row'
  }

  // isFilter - флаг указывает на то, запускается ли метод после изменения фильтрации
  @Bind
  @Debounce(500)
  private fetchMasterGroups(isFilter = false) {
    const { CancelToken } = axios
    if (this.isLoading && this.source) {
      this.source.cancel()
    }
    this.source = CancelToken.source()
    this.isLoading = true
    ManagerGroupsModule.fetchGroups(this.source.token)
      .then(() => {
        this.isLoading = false
        if (!isFilter) {
          this.masterGroupsExpanded = []
          this.loadedGroups = []
          this.showEditMasterGroupModal = false
          this.showTransferMasterModal = false
        }
      })
      .catch(err => {
        if (!axios.isCancel(err)) {
          this.notifyError(err)
          this.isLoading = false
        }
      })
  }

  private fetchMasters (groupID: number) {
    ManagerGroupsModule.fetchGroupMasters({
      masterGroupID: groupID,
      params: omit(this.filter, ['query']),
    })
      .then((response: MasterShortResource[]) => {
        this.loadedGroups.push({
          id: groupID,
          masters: [...response],
        })
      })
      .catch(this.notifyError)
  }

  private onCourseChange () {
    this.filter.groupType = undefined
    this.filter.monthId = undefined
  }

  private onGroupTypeChange () {
    this.filter.monthId = undefined
  }

  private handleFilter (field: IMasterGroupsFilterType, value: any) {
    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }
  }

  private handleExpandGroup (payload: { item: MasterGroupShortResource, value: boolean }) {
    if (payload.value && !this.loadedGroups.find((group: ILoadedGroup) => payload.item.id === group.id)) {
      this.fetchMasters(payload.item.id)
    }
  }

  private handleEditGroup (group: MasterGroupShortResource) {
    ManagerGroupsModule.fetchGroup(group.id)
      .then(() => {
        this.showEditMasterGroupModal = true
      })
      .catch(this.notifyError)
  }

  private handleTransferMaster (group: MasterGroupShortResource, master: MasterShortResource) {
    this.selectedGroup = group
    this.selectedMaster = master
    this.showTransferMasterModal = true
  }

  private confirmDeleteGroup (group: MasterGroupShortResource) {
    this.confirm.open(
      'Удаление группы мастеров',
      `Вы действительно хотите удалить <span class="text-body-3 secondary--text">${group.title}</span>? После удаления группу невозможно будет восстановить.`,
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(() => {
        this.handleDeleteGroup(group)
      })
      .catch(() => {return})
  }

  private handleDeleteGroup (group: MasterGroupShortResource) {
    ManagerGroupsModule.deleteGroup(group.id)
      .then(() => {
        this.fetchMasterGroups()
        this.notifySuccess('Группа удалена')
      })
      .catch(this.notifyError)
  }
}
