




import { MetaInfo } from 'vue-meta'
import { Vue, Component } from 'vue-property-decorator'

import MasterGroupsTable from '@/components/tables/MasterGroupsTable.vue'
import { IMasterGroupsFilter } from '@/store/types'

@Component({
  components: { MasterGroupsTable },
})
export default class GroupsList extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'Группы мастеров',
    }
  }

  private handleFilter(filter: IMasterGroupsFilter) {
    this.$emit('filter', { allocated: true, payload: { ...filter } })
  }
}
